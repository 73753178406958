import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import {
  DashboardService,
  ProductLineStatisticsItem,
} from '@claim-management-lib/data-access';
import { api_version } from '@claim-management-lib/feat-claim-shared';
import { DsPresetCalenderHeaderComponent } from '@design-system/components/advanced-datepicker';
import { TranslateService } from '@ngx-translate/core';
import { DateUtils } from '@paldesk/shared-lib/utils/date-utils';
import { LegendPosition } from '@swimlane/ngx-charts';
import { Observable, map } from 'rxjs';
import { ChartData } from '../dashboard.component';

@Component({
  selector: 'cm-claims-by-product-line',
  templateUrl: './claims-by-product-line.component.html',
  styleUrls: ['./claims-by-product-line.component.scss'],
  standalone: false,
})
export class ClaimsByProductLineComponent implements OnInit {
  claimsByProductLine$: Observable<ChartData[]>;

  colorScheme = {
    domain: [
      '#758087',
      '#ff5864',
      '#41b563',
      '#3c88ec',
      '#f3b980',
      '#4b575f',
      '#d9585e',
      '#3c88ec',
    ],
  };
  view: [number, number] = [280, 300];
  legendPosition = LegendPosition.Below;

  dateRange: FormGroup<{
    from: FormControl<Date | null>;
    to: FormControl<Date | null>;
  }>;
  today = new Date();

  DsPresetCalenderHeaderComponent = DsPresetCalenderHeaderComponent;

  constructor(
    private translateService: TranslateService,
    private dashboardService: DashboardService,
    private fb: FormBuilder,
    private dateAdapter: DateAdapter<any>,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.dateRange = this.fb.group({
      from: new FormControl(
        this.dateAdapter.addCalendarDays(this.today, -89),
        {},
      ),
      to: new FormControl(this.today, {}),
    });

    this.getData();
  }

  getData() {
    const from = this.dateRange.value.from;
    const to = this.dateRange.value.to;
    this.claimsByProductLine$ = this.dashboardService
      .getProductLineStatistics(
        api_version,
        from ? new Date(DateUtils.toISODateStringLocal(from)) : undefined,
        to ? new Date(DateUtils.toISODateStringLocal(to)) : undefined,
      )
      .pipe(map((r) => this.mapToChartData(r.product_lines)));
  }

  mapToChartData(items?: ProductLineStatisticsItem[]): ChartData[] {
    return (
      items?.map((product_line) => ({
        name: this.translateService.instant(
          'general.product_types.' + product_line.equipment_type,
        ),
        value: product_line.claim_count,
        extra: product_line.equipment_type,
      })) || []
    );
  }

  goToClaimsByProductLine(event) {
    if (!event.extra) {
      return;
    }
    this.router.navigate(['/claimdesk', 'custom'], {
      queryParams: {
        customFilter: 'ByProductLine',
        productLineEquipmentType: event.extra,
        start_date: `date(${DateUtils.toISODateStringLocal(
          this.dateRange.controls['from'].value || undefined,
        )})`,
        end_date: `date(${DateUtils.toISODateStringLocal(
          this.dateRange.controls['to'].value || undefined,
        )})`,
      },
    });
  }
}
