import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  GetClaimTypesResponseAvailableClaimTypes,
  GetMaterialInformationResponseMaterialInformationResult,
  MaterialService,
  SearchMaterialNumber,
} from '@claim-management-lib/data-access';
import { DateUtils } from '@paldesk/shared-lib/utils/date-utils';
import { nameofFactory } from '@utils/name-of';
import { debounceTime, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { api_version } from '../../constants';
import {
  MaterialNumberComparer,
  ValidParentErrorStateMatcher,
} from '../../utils';
import { FaultCausingPartValidationData } from '../fault-causing-part/fault-causing-part.models';
import {
  SparePartFormValues,
  SparePartValidators,
} from './spare-part-validators';

export interface GetMaterialInfoDto {
  equipmentNumber: string;
  repairDate: Date;
  userLanguage: string;
  servicePartnerId?: number;
  claimType: GetClaimTypesResponseAvailableClaimTypes;
}

@Component({
  selector: 'cm-spare-part',
  templateUrl: './spare-part.component.html',
  styleUrls: ['./spare-part.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SparePartComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SparePartComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SparePartComponent
  implements OnInit, AfterViewInit, ControlValueAccessor, OnDestroy
{
  @Input() getMaterialInfoDto: FaultCausingPartValidationData;

  @Input() set materialNumberRequired(value: boolean) {
    this._materialNumberRequired = value;
  }
  get materialNumberRequired(): boolean {
    return this._materialNumberRequired;
  }

  @Input() servicePartnerId: number;
  @Input() blackList: string[] = [];
  @Input() notAllowedFaultPart: string;
  @Output() hasFocus = new EventEmitter<boolean>();

  @Input() label: string;

  @ViewChild('spare_part_materialNumber', { static: true })
  materialNumberElement;

  form: FormGroup<{
    materialId: FormControl<number | undefined>;
    materialNumber: FormControl<string | undefined>;
    price: FormControl<number | undefined>;
    materialDescription: FormControl<string | undefined>;
    isPriceEditable: FormControl<boolean | undefined>;
    spare_part_id: FormControl<number | undefined>;
  }>;
  validParentMatcher = new ValidParentErrorStateMatcher(false, true);

  materialSuggestions$: Observable<SearchMaterialNumber[] | undefined>;
  materialNumberChangesOnChange$ = new Subject<string>();

  private _materialNumberRequired = false;
  private _isValidating = false;
  private _isSearchingSuggestions = false;
  private nameof = nameofFactory<SparePartFormValues>();
  private readonly destroy$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private materialService: MaterialService,
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group(
      {
        materialId: new FormControl<number | undefined>(undefined, {
          nonNullable: true,
        }),
        materialNumber: new FormControl<string | undefined>(undefined, {
          validators: this._materialNumberRequired ? [Validators.required] : [],
          nonNullable: true,
        }),
        price: new FormControl<number | undefined>(undefined, {
          nonNullable: true,
        }),
        materialDescription: new FormControl<string | undefined>(
          {
            value: undefined,
            disabled: true,
          },
          {
            nonNullable: true,
          },
        ),
        isPriceEditable: new FormControl<boolean | undefined>(
          {
            value: undefined,
            disabled: true,
          },
          {
            nonNullable: true,
          },
        ),
        spare_part_id: new FormControl<number | undefined>(
          { value: undefined, disabled: true },
          { nonNullable: true },
        ),
      },
      {
        validators: [
          SparePartValidators.validating(this),
          SparePartValidators.validSparePart(this),
          SparePartValidators.required(this),
          SparePartValidators.unique(this),
          SparePartValidators.forbiddenFaultPart(this),
        ],
      },
    );
  }

  get materialNumberControl(): FormControl {
    return this.form.controls[this.nameof('materialNumber')] as FormControl;
  }

  ngAfterViewInit() {
    this.materialSuggestions$ = this.materialNumberControl.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged((oldVal, newVal) =>
        MaterialNumberComparer.compare(oldVal, newVal),
      ),
      filter((val) => !this.materialNumberControl.disabled && val),
      switchMap((val) => {
        this.isSearchingSuggestions = true;
        return this.materialService
          .searchMaterialNumber(
            val,
            this.getMaterialInfoDto.userLanguage,
            api_version,
          )
          .pipe(
            map((x) => x.materials),
            finalize(() => {
              this.isSearchingSuggestions = false;
            }),
          );
      }),
      takeUntil(this.destroy$),
    );

    this.materialNumberChangesOnChange$
      .pipe(
        distinctUntilChanged((oldVal, newVal) =>
          MaterialNumberComparer.compare(oldVal, newVal),
        ),
        filter(() => !this.materialNumberControl.disabled),
        switchMap((val) => {
          if (
            val &&
            this.getMaterialInfoDto &&
            !this.materialNumberControl.disabled
          ) {
            this.isValidating = true;
            this.value = this.createInvalidFormValues(val);
            return this.materialService
              .getMaterialInformation(
                this.getMaterialInfoDto.equipmentNumber,
                val.toString(),
                this.getMaterialInfoDto.claimType,
                DateUtils.toISODateStringLocal(
                  this.getMaterialInfoDto.repairDate,
                ),
                api_version,
                this.getMaterialInfoDto.userLanguage,
                this.servicePartnerId && this.servicePartnerId > 0
                  ? this.servicePartnerId
                  : undefined,
              )
              .pipe(
                map((materialInfoResult) => {
                  if (
                    materialInfoResult.material_information_result ===
                    GetMaterialInformationResponseMaterialInformationResult.Invalid
                  ) {
                    return this.createInvalidFormValues(val);
                  } else {
                    return {
                      materialId:
                        materialInfoResult.material_info_dto?.material_id,
                      materialNumber:
                        materialInfoResult.material_info_dto?.material_number,
                      materialDescription:
                        materialInfoResult.material_info_dto?.description,
                      price: materialInfoResult.material_info_dto?.price,
                      isPriceEditable:
                        materialInfoResult.material_info_dto?.is_price_editable,
                      spare_part_id: undefined,
                    } as SparePartFormValues;
                  }
                }),
                catchError(() => of(this.createInvalidFormValues(val))),
                takeUntil(this.destroy$),
              );
          }
          return of(this.createInvalidFormValues(val));
        }),
      )
      .subscribe((result) => {
        this.isValidating = false;
        this.writeValue(result);
        this.materialNumberControl.updateValueAndValidity();
      });
  }

  createInvalidFormValues(materialNumber: string): SparePartFormValues {
    return {
      materialId: -1,
      materialNumber: materialNumber,
      materialDescription: '',
      price: 0,
      isPriceEditable: false,
      spare_part_id: undefined,
    };
  }

  set value(value: SparePartFormValues) {
    this.form.patchValue(value);
    this.onChange(value);
    this.onTouched();
  }
  get value(): SparePartFormValues {
    return this.form.value as SparePartFormValues;
  }

  get isValidating(): boolean {
    return this._isValidating;
  }
  set isValidating(value: boolean) {
    this._isValidating = value;
  }

  get isSearchingSuggestions(): boolean {
    return this._isSearchingSuggestions;
  }
  set isSearchingSuggestions(value: boolean) {
    this._isSearchingSuggestions = value;
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  onFocusChange($event) {
    this.hasFocus.emit($event.type === 'focus');
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      if (this.value && this.value.materialId === value.materialId) {
        return;
      }

      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.materialNumberControl.disable();
    } else {
      this.materialNumberControl.enable();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validate(control: FormControl): ValidationErrors | null {
    return this.form.valid ? null : this.form.errors;
  }

  getDescriptionTooltip() {
    return this.form.controls[this.nameof('materialDescription')].value;
  }

  get showLoading(): boolean {
    return (
      (this.isValidating || this.isSearchingSuggestions) &&
      !this.materialNumberControl.disabled
    );
  }

  get showSuccess(): boolean {
    return (
      !this.materialNumberControl.disabled &&
      this.materialNumberControl.value && // if empty we don't want to show tick
      this.validate(this.materialNumberControl) === null &&
      !this.showLoading
    );
  }

  preventDefault(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }

  triggerMaterialNumberChange() {
    this.materialNumberChangesOnChange$.next(this.materialNumberControl.value);
  }
}
