import { Component, OnInit } from '@angular/core';
import { UserService } from '@features/auth';

@Component({
  selector: 'cm-contract-terms',
  templateUrl: './contract-terms.component.html',
  styleUrls: ['./contract-terms.component.scss'],
  standalone: false,
})
export class ContractTermsComponent implements OnInit {
  public isGermanUser: boolean;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.isGermanUser = this.userService.isGermany();
  }
}
