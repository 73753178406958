import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import {
  DashboardService,
  PeriodGroupClaim,
  PeriodGroupStatistics,
  PermissionService,
} from '@claim-management-lib/data-access';
import { api_version } from '@claim-management-lib/feat-claim-shared';
import { DsPresetCalenderHeaderComponent } from '@design-system/components/advanced-datepicker';
import { TranslateService } from '@ngx-translate/core';
import { DateUtils } from '@paldesk/shared-lib/utils/date-utils';
import { LegendPosition } from '@swimlane/ngx-charts';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { UserService } from '@features/auth';
import {
  CustomPeriodsDialogComponent,
  CustomPeriodsDialogData,
} from './custom-periods-dialog-component/custom-periods-dialog-component.component';
import { MatDialog } from '@angular/material/dialog';
import { map, startWith } from 'rxjs/operators';

export enum PeriodMode {
  Return = 'Return',
  Create = 'Create',
}

interface GroupChartData {
  series: {
    name: string;
    value: number;
    extra?: any;
  }[];
}

interface ChartDataSet {
  total_count: number;
  groups: GroupChartData[];
}

interface ChartData {
  chartDataSp?: ChartDataSet;
  chartDataGa?: ChartDataSet;
}

@Component({
  selector: 'cm-claims-by-period',
  templateUrl: './claims-by-period.component.html',
  styleUrls: ['./claims-by-period.component.scss'],
  standalone: false,
})
export class ClaimsByPeriodComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private claimsByPeriodSubject = new BehaviorSubject<ChartData | null>(null);
  claimsByPeriod$ = this.claimsByPeriodSubject.asObservable();
  repairSentDateDays: number;

  colorScheme = {
    domain: ['#41b563', '#758087', '#ff5864', '#3c88ec'],
  };
  view: [number, number] = [280, 300];
  legendPosition = LegendPosition.Below;

  dateRange: FormGroup<{
    from: FormControl<Date | null>;
    to: FormControl<Date | null>;
  }>;
  today = new Date();

  periodMode = PeriodMode;
  modeSelector: FormControl<PeriodMode> = new FormControl(PeriodMode.Create, {
    nonNullable: true,
  });

  DsPresetCalenderHeaderComponent = DsPresetCalenderHeaderComponent;

  repairSentDatePercentageGoal: number;
  repairSentDateCurrentPercentage: number;

  isDecisionGA$: Observable<boolean>;

  constructor(
    private fb: FormBuilder,
    private dashboardService: DashboardService,
    private translateService: TranslateService,
    private dateAdapter: DateAdapter<any>,
    private router: Router,
    private userService: UserService,
    private dialog: MatDialog,
    private permissionService: PermissionService,
  ) {}

  ngOnInit(): void {
    this.dateRange = this.fb.group({
      from: new FormControl(this.dateAdapter.addCalendarDays(this.today, -89)),
      to: new FormControl(this.today),
    });

    this.getData();

    this.modeSelector.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.getData();
      });

    this.isDecisionGA$ = this.permissionService
      .getClaimUserContext(api_version)
      .pipe(
        map((response) => response.is_decision_general_agent),
        startWith(false),
        takeUntil(this.destroy$),
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.claimsByPeriodSubject.complete();
  }

  getData(): void {
    this.claimsByPeriodSubject.next(null);
    const from = this.dateRange.value.from;
    const to = this.dateRange.value.to;

    const serviceCall =
      this.modeSelector.value === PeriodMode.Return
        ? this.dashboardService.getReturnedSentGroups(
            api_version,
            from ? new Date(DateUtils.toISODateStringLocal(from)) : undefined,
            to ? new Date(DateUtils.toISODateStringLocal(to)) : undefined,
          )
        : this.dashboardService.getRepairedSentGroups(
            api_version,
            from ? new Date(DateUtils.toISODateStringLocal(from)) : undefined,
            to ? new Date(DateUtils.toISODateStringLocal(to)) : undefined,
          );

    serviceCall.pipe(takeUntil(this.destroy$)).subscribe((response) => {
      const chartData = this.setGroupChartData(response);
      this.claimsByPeriodSubject.next(chartData);
    });
  }

  setGroupChartData(stats: PeriodGroupStatistics): ChartData {
    const resGa: GroupChartData[] = stats.claim_groups_ga
      ? this.getGroups(stats.claim_groups_ga, stats.total_count)
      : [];

    const resSp: GroupChartData[] = stats.claim_groups
      ? this.getGroups(stats.claim_groups, stats.total_count)
      : [];

    if (resGa.length && resGa[0].series.length) {
      this.processGroup(resGa[0], stats);
    } else if (resSp.length && resSp[0].series.length) {
      this.processGroup(resSp[0], stats);
    }

    return {
      chartDataGa: {
        total_count: stats.total_count,
        groups:
          resGa.length && resGa[0].series.some((gr) => gr.value > 0)
            ? resGa
            : [],
      },
      chartDataSp: {
        total_count: stats.total_count,
        groups:
          resSp.length && resSp[0].series.some((gr) => gr.value > 0)
            ? resSp
            : [],
      },
    };
  }

  private processGroup(
    data: GroupChartData,
    stats: PeriodGroupStatistics,
  ): void {
    const group = data.series.find((gr) => !gr.extra.day_diff_from_inclusive);
    if (group) {
      this.repairSentDatePercentageGoal = group.extra.goal || 0;
      this.repairSentDateCurrentPercentage =
        (group.extra.claim_count * 100) / stats.total_count;
      this.repairSentDateDays = group.extra.day_diff_to_exclusive;
    }
  }

  private getGroups(
    claim_groups: Array<PeriodGroupClaim>,
    total_count: number,
  ): GroupChartData[] {
    return [
      {
        series:
          claim_groups?.map((group) => {
            let name: string;
            if (!group.day_diff_from_inclusive) {
              this.repairSentDatePercentageGoal = group.goal || 0;
              this.repairSentDateCurrentPercentage =
                (group.claim_count * 100) / total_count;
              name = this.translateService.instant(
                'claim-management.dashboard.group.less_than',
                {
                  to: group.day_diff_to_exclusive,
                },
              );
            } else if (!group.day_diff_to_exclusive) {
              name = this.translateService.instant(
                'claim-management.dashboard.group.more_than',
                {
                  from: group.day_diff_from_inclusive,
                },
              );
            } else {
              name = this.translateService.instant(
                'claim-management.dashboard.group.range',
                {
                  from: group.day_diff_from_inclusive,
                  to: group.day_diff_to_exclusive,
                },
              );
            }

            return {
              name,
              value: group.claim_count || 0,
              extra: group,
            };
          }) || [],
      },
    ];
  }

  goToClaimsByPeriod(event): void {
    if (!event.extra) {
      return;
    }
    this.router.navigate(['/claimdesk', 'custom'], {
      queryParams: {
        customFilter: 'ByPeriod',
        periodDayDiffFrom: event.extra.day_diff_from_inclusive,
        periodDayDiffTo: event.extra.day_diff_to_exclusive,
        periodFilterType:
          this.modeSelector.value === PeriodMode.Return
            ? 'ReturnedSentGroups'
            : 'RepairedSentGroups',
        start_date: `date(${DateUtils.toISODateStringLocal(
          this.dateRange.controls['from'].value || undefined,
        )})`,
        end_date: `date(${DateUtils.toISODateStringLocal(
          this.dateRange.controls['to'].value || undefined,
        )})`,
      },
    });
  }

  openSettings(): void {
    const currentValue = this.claimsByPeriodSubject.getValue();
    const dialogRef = this.dialog.open(CustomPeriodsDialogComponent, {
      width: '400px',
      data: {
        lowerBoundary:
          currentValue?.chartDataSp?.groups?.[0]?.series?.[0]?.extra
            ?.day_diff_to_exclusive,
        higherBoundary:
          currentValue?.chartDataSp?.groups?.[0]?.series?.[1]?.extra
            ?.day_diff_to_exclusive,
        periodMode: this.modeSelector.value,
      } as CustomPeriodsDialogData,
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result) {
          this.getData();
        }
      });
  }
}
