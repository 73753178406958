import { Component, Inject } from '@angular/core';
import { DsSpotlight } from '@design-system/feature/spotlight';
import { UserService } from '@features/auth';
import { DsAppWrapperConfig, DS_APP_WRAPPER_CONFIG } from '../config';
import { AppWrapperService } from '../app-wrapper.service';

@Component({
  selector: 'ds-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  standalone: false,
})
export class UserComponent {
  paldeskSupportPath = 'https://status-paldesk.palfinger.com';
  copyrightYear = new Date().getFullYear();

  constructor(
    public userService: UserService,
    @Inject(DS_APP_WRAPPER_CONFIG) public config: DsAppWrapperConfig,
    private _dsSpotlight: DsSpotlight,
    private wrapperService: AppWrapperService,
  ) {
    if (config.paldeskSupportPath) {
      this.paldeskSupportPath = config.paldeskSupportPath;
    } else if (config.paldeskBasePath?.includes('test')) {
      this.paldeskSupportPath = 'https://status-paldesk-test.palfinger.com';
    }
  }

  logout() {
    this.userService.logout();
  }

  login() {
    this.userService.login();
  }

  openUserProfile(): void {
    this.userService.navigateToProfile();
  }

  resetSpotlight() {
    this._dsSpotlight.resetWholeSpotlight();
    this.wrapperService.drawerClose();
  }
}
