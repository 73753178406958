import { CommonModule, DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ServiceContract } from '@claim-management-lib/data-access';
import { TranslateModule } from '@ngx-translate/core';
import { ContractTermsComponent } from '@paldesk/shared-lib/features/contract-terms';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'cm-summary-contracts',
  templateUrl: './summary-contracts.component.html',
  styleUrls: ['./summary-contracts.component.scss'],
  imports: [DatePipe, TranslateModule, CommonModule, MatTooltipModule],
})
export class SummaryContractsComponent {
  @Input() contracts: ServiceContract[];

  constructor(private dialog: MatDialog) {}

  openContractTerms(contract: ServiceContract) {
    this.dialog.open(ContractTermsComponent, {
      data: {
        contract_area: contract.service_contract_area,
        contract_type: contract.service_contract_type,
      },
    });
  }
}
