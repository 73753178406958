<div fxLayout="column" [gaCategoryGroup]="gaCategory ?? 'Satisfaction Survery'">
  <span
    fxLayout="row"
    fxLayoutAlign="space-between center"
    [fxLayoutGap]="0.25 | dsSpacing"
    ><h3 class="no-margin">
      {{ 'design_system.satisfaction_survey.title' | translate }}
    </h3>
    <button
      mat-icon-button
      (click)="close()"
      [gaClickListener]="'Close Survey'"
      data-cy="ds-satisfaction-feedback-close"
    >
      <mat-icon>close</mat-icon>
    </button></span
  >
  @if (promoText) {
    <p data-cy="ds-satisfaction-feedback-text">{{ promoText | translate }}</p>
  }
  <span>
    <button
      mat-raised-button
      color="primary"
      (click)="openSurveyDialog()"
      [gaClickListener]="'Start Survey'"
      data-cy="ds-satisfaction-feedback-start"
    >
      {{ 'design_system.satisfaction_survey.start_survey_button' | translate }}
    </button>
  </span>
</div>
