import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  ClaimAccessResponse,
  ClaimAccessResponseAccessStatus,
  PermissionService,
} from '@claim-management-lib/data-access';
import { finalize } from 'rxjs/operators';
import { api_version } from '../../constants';

@Component({
  selector: 'pd-create-claim',
  templateUrl: './create-claim.component.html',
  styleUrls: ['./create-claim.component.scss'],
  standalone: false,
})
export class CreateClaimComponent {
  equipmentNumber = new FormControl<string>('', { nonNullable: true });
  requestPending = false;
  errorKey?: string;

  constructor(
    private permissionService: PermissionService,
    private router: Router,
    private dialogRef: MatDialogRef<CreateClaimComponent>,
  ) {}

  createClaim() {
    this.requestPending = true;
    this.errorKey = undefined;
    this.equipmentNumber.setErrors(null);

    this.permissionService
      .getPermissionForEquipment(this.equipmentNumber.value.trim(), api_version)
      .pipe(finalize(() => (this.requestPending = false)))
      .subscribe((check) => {
        this.handleCheckResponse(check);
      });
  }

  handleCheckResponse(check: ClaimAccessResponse) {
    if (check.access_status === ClaimAccessResponseAccessStatus.Success) {
      this.router.navigate(['/claim/' + this.equipmentNumber.value.trim()]);
      this.dialogRef.close();
    } else {
      this.equipmentNumber.setErrors({ invalidEquipment: true });
      this.errorKey = 'claim-management.permission.' + check.access_status;
    }
  }
}
