/**
 * v1.1 - Claim Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1_claim_management
 * Contact: F.John@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CreditNote } from './creditNote';
import { Attachment } from './attachment';
import { DeliverySparePartGet } from './deliverySparePartGet';


export interface GetDeliveryClaimResponse { 
    
    claim_number?: string;
    
    description?: string;
    
    delivery_note_number?: string;
    
    equipment_number?: string;
    
    decision_reason?: string;
    
    delivery_type: GetDeliveryClaimResponseDeliveryType;
    
    delivery_claim_reason?: GetDeliveryClaimResponseDeliveryClaimReason;
    
    is_recent: boolean;
    
    claim_state_id: GetDeliveryClaimResponseClaimStateId;
    
    internal_note?: string;
    
    claim_process?: GetDeliveryClaimResponseClaimProcess;
    
    credit_note?: string;
    
    modified_by: string;
    
    modified_on: Date;
    
    delivery_spare_parts?: Array<DeliverySparePartGet>;
    
    attachments?: Array<Attachment>;
    
    company_attachments?: Array<Attachment>;
    
    sap_attachments?: Array<Attachment>;
    
    credit_notes?: Array<CreditNote>;
}
export enum GetDeliveryClaimResponseDeliveryType {
    Palfinger = 'Palfinger',
    GeneralAgent = 'GeneralAgent'
};
export enum GetDeliveryClaimResponseDeliveryClaimReason {
    MissingParts = 'MissingParts',
    WrongPartsDelivered = 'WrongPartsDelivered',
    OverDelivery = 'OverDelivery',
    QualityIssueOrDamage = 'QualityIssueOrDamage',
    TransportDamage = 'TransportDamage'
};
export enum GetDeliveryClaimResponseClaimStateId {
    DraftServicePartner = 'DraftServicePartner',
    DraftGeneralAgent = 'DraftGeneralAgent',
    DraftDecisionGeneralAgent = 'DraftDecisionGeneralAgent',
    SentToGeneralAgent = 'SentToGeneralAgent',
    SentToArea = 'SentToArea',
    InformationGivenToGeneralAgent = 'InformationGivenToGeneralAgent',
    ReturnedToServicePartner = 'ReturnedToServicePartner',
    ReturnedToGeneralAgent = 'ReturnedToGeneralAgent',
    PartsRequestedFromServicePartner = 'PartsRequestedFromServicePartner',
    PartsRequestedFromGeneralAgent = 'PartsRequestedFromGeneralAgent',
    PartsSentToGeneralAgent = 'PartsSentToGeneralAgent',
    PartsSentToArea = 'PartsSentToArea',
    AcceptedFullyByArea = 'AcceptedFullyByArea',
    AcceptedFullyByGeneralAgent = 'AcceptedFullyByGeneralAgent',
    AcceptedPartlyByArea = 'AcceptedPartlyByArea',
    AcceptedPartlyByGeneralAgent = 'AcceptedPartlyByGeneralAgent',
    DeclinedByArea = 'DeclinedByArea',
    DeclinedByGeneralAgent = 'DeclinedByGeneralAgent'
};
export enum GetDeliveryClaimResponseClaimProcess {
    MaterialDelivery = 'MaterialDelivery',
    CreditNote = 'CreditNote'
};



