import { AfterViewChecked, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  ErrorCoding,
  ErrorCodings,
  ErrorCodingsDescriptions,
} from '@claim-management-lib/data-access';

export interface SelectedErrorCodes {
  errorCodes: ErrorCodings;
  errorDescriptions: ErrorCodingsDescriptions;
}

@Component({
  selector: 'cm-error-code-tree',
  templateUrl: './error-code-tree.component.html',
  styleUrls: ['./error-code-tree.component.scss'],
  standalone: false,
})
export class ErrorCodeTreeComponent implements OnInit, AfterViewChecked {
  @Input() faultDescriptionForm: FormGroup;
  @Input() errorCodingTree: ErrorCoding[];

  @Input() set savedErrorCodes(value: SelectedErrorCodes) {
    this._savedErrorCodes = value;
    this.showSavedData();
  }
  get savedErrorCodes() {
    return this._savedErrorCodes;
  }

  @Input() ReadOnlyMode: boolean;

  thirdLevelNodes?: ErrorCoding[];
  selectedDescriptions: ErrorCodingsDescriptions = {
    error_location_description: '',
    error_location_detail_description: '',
    error_type_description: '',
    error_type_detail_description: '',
  };
  originalTreeData: ErrorCoding[];
  originalThirdLevelNodes?: ErrorCoding[];

  leftSearchTextControl = new FormControl<string | undefined>(undefined, {
    nonNullable: true,
  });
  rightSearchTextControl = new FormControl<string | undefined>(undefined, {
    nonNullable: true,
  });

  private _savedErrorCodes: SelectedErrorCodes;

  ngOnInit() {
    this.showSavedData();
  }

  ngAfterViewChecked() {
    if (this.errorCodingTree && !this.originalTreeData) {
      this.originalTreeData = Object.assign(this.errorCodingTree);
    }
  }

  filterTree(data: any, searchValue: string): boolean {
    return (
      (
        data.sap_code.toLowerCase() +
        ' ' +
        data.description.toLowerCase()
      ).indexOf(searchValue.toLowerCase()) > -1 ||
      data.children.find(
        (ch) =>
          (
            ch.sap_code.toLowerCase() +
            ' ' +
            ch.description.toLowerCase()
          ).indexOf(searchValue.toLowerCase()) > -1,
      )
    );
  }

  onLeftTreeSearchChange(searchValue: EventTarget | null) {
    if (searchValue) {
      this.errorCodingTree = this.originalTreeData.filter((data) =>
        this.filterTree(data, searchValue['value']),
      );
    }
  }
  onRightTreeSearchChange(searchValue: EventTarget | null) {
    if (searchValue) {
      this.thirdLevelNodes = this.originalThirdLevelNodes
        ? this.originalThirdLevelNodes.filter((data) =>
            this.filterTree(data, searchValue['value']),
          )
        : undefined;
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  onTreeSelectedItem(level: number, node: ErrorCoding) {
    switch (level) {
      case 0:
        if (this.errorLocationControl.value === node.sap_code) {
          this.clearFirstLevelTreeSelection();
        } else {
          this.errorLocationControl.setValue(node.sap_code);
          this.selectedDescriptions.error_location_description =
            node.description;
          this.clearSecondLevelTreeSelection();
        }
        break;
      case 1:
        if (this.errorLocationDetailControl.value === node.sap_code) {
          this.clearSecondLevelTreeSelection();
        } else {
          this.errorLocationDetailControl.setValue(node.sap_code);
          this.selectedDescriptions.error_location_detail_description =
            node.description;
          this.thirdLevelNodes = this.originalThirdLevelNodes = node.children;
          if (document && window.matchMedia('(max-width: 600px)').matches) {
            const el = document.getElementById('rightTreeSearch');
            if (el) {
              //possibly null check
              el.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
          }
          this.clearThirdLevelTreeSelection();
        }
        break;
      case 2:
        if (this.errorTypeControl.value === node.sap_code) {
          this.clearThirdLevelTreeSelection();
        } else {
          this.errorTypeControl.setValue(node.sap_code);
          this.selectedDescriptions.error_type_description = node.description;
          this.clearFourthLevelTreeSelection();
        }
        break;
      case 3:
        if (this.errorTypeDetailControl.value === node.sap_code) {
          this.clearFourthLevelTreeSelection();
        } else {
          this.errorTypeDetailControl.setValue(node.sap_code);
          this.selectedDescriptions.error_type_detail_description =
            node.description;

          this.leftSearchTextControl.setValue(undefined);
          this.errorCodingTree = this.originalTreeData;

          this.rightSearchTextControl.setValue(undefined);
          this.thirdLevelNodes = this.originalThirdLevelNodes;
        }
        break;
    }
  }

  clearFirstLevelTreeSelection() {
    this.errorLocationControl.setValue(undefined);
    this.selectedDescriptions.error_location_description = '';
    this.clearSecondLevelTreeSelection();
  }

  clearSecondLevelTreeSelection() {
    this.errorLocationDetailControl.setValue(undefined);
    this.selectedDescriptions.error_location_detail_description = '';
    this.thirdLevelNodes = undefined;
    this.clearThirdLevelTreeSelection();
  }

  clearThirdLevelTreeSelection() {
    this.errorTypeControl.setValue(undefined);
    this.selectedDescriptions.error_type_description = '';
    this.clearFourthLevelTreeSelection();
  }

  clearFourthLevelTreeSelection() {
    this.errorTypeDetailControl.setValue(undefined);
    this.selectedDescriptions.error_type_detail_description = '';
  }

  showSavedData() {
    if (this.savedErrorCodes?.errorCodes && this.errorCodingTree) {
      this.errorLocationControl.setValue(
        this.savedErrorCodes.errorCodes.error_location,
        { emitEvent: false },
      );
      this.errorLocationDetailControl.setValue(
        this.savedErrorCodes.errorCodes.error_location_detail,
        { emitEvent: false },
      );
      this.errorTypeControl.setValue(
        this.savedErrorCodes.errorCodes.error_type,
        { emitEvent: false },
      );
      this.errorTypeDetailControl.setValue(
        this.savedErrorCodes.errorCodes.error_type_detail,
        { emitEvent: false },
      );

      const errorDescriptions = this.savedErrorCodes.errorDescriptions;
      this.selectedDescriptions = {
        error_location_description:
          errorDescriptions.error_location_description,
        error_location_detail_description:
          errorDescriptions.error_location_detail_description,
        error_type_description: errorDescriptions.error_type_description,
        error_type_detail_description:
          errorDescriptions.error_type_detail_description,
      };

      const filtered_node = this.errorCodingTree.filter(
        (x) => x.sap_code === this.savedErrorCodes.errorCodes.error_location,
      )[0];
      if (filtered_node && filtered_node.children) {
        const parentNode = filtered_node.children.filter(
          (x) =>
            x.sap_code ===
            this.savedErrorCodes.errorCodes.error_location_detail,
        )[0];
        if (parentNode) {
          this.thirdLevelNodes = parentNode.children;
        }
      }

      //notify summary about new values
      this.faultDescriptionForm.updateValueAndValidity();
    } else {
      this.selectedDescriptions = {
        error_location_description: '',
        error_location_detail_description: '',
        error_type_description: '',
        error_type_detail_description: '',
      };
    }
  }

  get errorLocationTitleText() {
    if (this.selectedDescriptions.error_location_detail_description) {
      return `: ${this.errorLocationControl.value} ${this.errorLocationDetailControl.value} - ${this.selectedDescriptions.error_location_detail_description}`;
    }

    if (this.errorLocationControl.value) {
      return ` *: ${this.errorLocationControl.value} -`;
    }

    return ' *: ';
  }

  get errorTypeTitleText() {
    if (this.selectedDescriptions.error_type_detail_description) {
      return `: ${this.errorTypeControl.value} ${this.errorTypeDetailControl.value} - ${this.selectedDescriptions.error_type_detail_description}`;
    }

    if (this.errorTypeControl.value) {
      return ` *: ${this.errorTypeControl.value} -`;
    }

    return ' *: ';
  }

  get errorLocationControl(): FormControl<string | undefined> {
    return this.faultDescriptionForm.controls['error_location'] as FormControl;
  }

  get errorLocationDetailControl(): FormControl<string | undefined> {
    return this.faultDescriptionForm.controls[
      'error_location_detail'
    ] as FormControl;
  }

  get errorTypeControl(): FormControl<string | undefined> {
    return this.faultDescriptionForm.controls['error_type'] as FormControl;
  }

  get errorTypeDetailControl(): FormControl<string | undefined> {
    return this.faultDescriptionForm.controls[
      'error_type_detail'
    ] as FormControl;
  }
}
