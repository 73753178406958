import { Component, Input } from '@angular/core';

@Component({
  selector: 'cm-accepted-declined-tooltip',
  templateUrl: './accepted-declined-tooltip.component.html',
  styleUrls: ['./accepted-declined-tooltip.component.scss'],
  standalone: false,
})
export class AcceptedDeclinedTooltipComponent {
  @Input() name: string;
  @Input() value: number;
  @Input() total: number;
  @Input() currency: string;
}
