import { Component } from '@angular/core';
import {
  DsDatepickerPreset,
  dsDefaultDateRangePresets,
} from '../datepicker-presets';

@Component({
  selector: 'ds-calendar-header',
  templateUrl: './calendar-header.component.html',
  styleUrls: ['./calendar-header.component.scss'],
  standalone: false,
})
export class DsPresetCalenderHeaderComponent {
  presets: DsDatepickerPreset[] = dsDefaultDateRangePresets;
}
