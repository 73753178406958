import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ActionLogService,
  GetActionLogsResponseItem,
} from '@claim-management-lib/data-access';
import {
  DsSnackbarService,
  DsSnackbarType,
} from '@design-system/feature/snackbar';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, finalize, map } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { api_version } from '../../constants';

export interface ClaimHistoryLogData {
  claimId: number;
  claimNumber: string;
}

@Component({
  selector: 'paldesk-claim-history-log-log',
  templateUrl: './claim-history-log.component.html',
  styleUrls: ['./claim-history-log.component.scss'],
  standalone: false,
})
export class ClaimHistoryLogComponent implements OnInit {
  historyLog$: Observable<GetActionLogsResponseItem[]>;
  pending = true;

  columns = ['message', 'created_on', 'created_by'];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ClaimHistoryLogData,
    private dialogRef: MatDialogRef<ClaimHistoryLogComponent>,
    private actionLogService: ActionLogService,
    private snackbar: DsSnackbarService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.historyLog$ = this.actionLogService
      .getActionLogsForClaim(this.data.claimId, api_version)
      .pipe(
        map((x) => x || []),
        catchError(() => {
          this.snackbar.queue(
            this.translateService.instant(
              'claim-management.claim_history_error',
            ),
            {
              type: DsSnackbarType.Error,
            },
          );
          this.dialogRef.close();
          return EMPTY;
        }),
        finalize(() => (this.pending = false)),
      );
  }
}
