<h1 mat-dialog-title>
  {{ 'claim-management.claim_history_log' | translate }}
  {{ data.claimNumber }}
</h1>

<mat-dialog-content>
  <ng-container *pdLet="historyLog$ | async as historyLog">
    <ds-table *ngIf="!pending">
      <table
        mat-table
        *ngIf="historyLog.length; else noData"
        [dataSource]="historyLog"
      >
        <ng-container matColumnDef="created_by">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'claim-management.claim_history.modified_by' | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.created_by }}
          </td>
        </ng-container>
        <ng-container matColumnDef="created_on">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'claim-management.claim_history.modified_on' | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.created_on | date: 'short' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="message">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'claim-management.claim_history.action' | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.message }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: columns"></tr>
      </table>
      <ds-table-loading
        *ngIf="pending"
        [numberOfHeaders]="3"
        [numberOfRows]="5"
      ></ds-table-loading>
    </ds-table>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions>
  <button [mat-dialog-close] mat-button>
    {{ 'general.close' | translate }}
  </button>
</mat-dialog-actions>

<ng-template #noData>
  <ds-placeholder type="no_data"></ds-placeholder>
</ng-template>
