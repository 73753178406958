import { Component, Inject } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetModule,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { TranslateModule } from '@ngx-translate/core';
import { SurveyDialogService } from '@paldesk/survey';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';
import { SatisfactionFeedbackData } from '../models/feedback-dialog-data';
import { select, Store } from '@ngrx/store';
import { SatisfactionFeedbackActions } from '../..';
import { SatisfactionFeedbackFeature } from './store/satisfaction-feedback.reducers';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'ds-satisfaction-feedback',
  templateUrl: './satisfaction-feedback.component.html',
  imports: [
    TranslateModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    DsSpacingModule,
    MatBottomSheetModule,
    GoogleAnalytics4DirectiveModule,
  ],
})
export class DsSatisfactionFeedbackComponent {
  promoText: string;
  paldeskCSISurveyId?: string = undefined;
  featureKey: string;
  gaCategory?: string = undefined;

  private _count = 0;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) private _data: SatisfactionFeedbackData,
    private _bottomSheetRef: MatBottomSheetRef<DsSatisfactionFeedbackComponent>,
    private _surveyService: SurveyDialogService,
    private _store: Store,
  ) {
    if (this._data?.promoText) {
      this.promoText = this._data.promoText;
    }
    if (this._data?.surveyId) {
      this.paldeskCSISurveyId = this._data.surveyId;
    }
    if (this._data?.featureKey) {
      this.featureKey = this._data.featureKey;
    }

    this._store
      .pipe(
        select(SatisfactionFeedbackFeature.selectConfig),
        takeUntilDestroyed(),
      )
      .subscribe((config) => {
        this._count = config?.[this.featureKey]?.count || 0;
      });
  }

  openSurveyDialog() {
    this._surveyService.openQualtricsSurvey(
      this.paldeskCSISurveyId
        ? this.paldeskCSISurveyId
        : SurveyDialogService.paldeskCSISurveyId,
    );
    this._store.dispatch(
      SatisfactionFeedbackActions.SetUserSettings({
        key: this.featureKey,
        value: { count: 5, date: new Date().toISOString() },
      }),
    );
    this._bottomSheetRef.dismiss();
  }

  close(): void {
    this._count++;
    this._store.dispatch(
      SatisfactionFeedbackActions.SetUserSettings({
        key: this.featureKey,
        value: { count: this._count, date: new Date().toISOString() },
      }),
    );
    this._bottomSheetRef.dismiss();
  }
}
