import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatChipGrid } from '@angular/material/chips';
import { MatDialogRef } from '@angular/material/dialog';
import {
  EmailNotificationService,
  EmailNotificationsSettings,
  EmailNotificationsSettingsUpdate,
} from '@claim-management-lib/data-access';
import { api_version } from '@claim-management-lib/feat-claim-shared';
import {
  DsSnackbarService,
  DsSnackbarType,
} from '@design-system/feature/snackbar';
import { TranslateService } from '@ngx-translate/core';
import { Subject, combineLatest, finalize, skip } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'paldesk-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss'],
  standalone: false,
})
export class NotificationSettingsComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  modifiedByGeneralAgent = false;
  savePending = false;
  isLoading = true;
  notificationsActive = false;
  languageControl: FormControl<string>;
  emailListControl: FormControl<string[]>;
  emailListDeliveryControl: FormControl<string[]>;

  languageCodes: string[] = ['en', 'es', 'fr', 'it', 'no', 'sv', 'de'];
  @ViewChild(MatChipGrid) chipList;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  constructor(
    private dialogRef: MatDialogRef<NotificationSettingsComponent>,
    private notificationService: EmailNotificationService,
    private snackbar: DsSnackbarService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.notificationService
      .getEmailNotificationsSettings(api_version)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (settings) => {
          this.notificationsActive = settings.send_claim_notifications;
          this.modifiedByGeneralAgent = settings.modified_by_general_agent;
          this.languageControl = new FormControl(
            settings.language_code || 'en',
            {
              nonNullable: true,
            },
          );
          this.initEmailList(settings);
        },
        error: (error) => this.handleError(error),
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  initEmailList(settings: EmailNotificationsSettings): void {
    this.emailListControl = new FormControl(
      settings.claim_notifications_email?.split(',') || [],
      {
        nonNullable: true,
      },
    );

    this.emailListDeliveryControl = new FormControl(
      settings.delivery_claim_notifications_email?.split(',') || [],
      {
        nonNullable: true,
      },
    );

    combineLatest([
      this.emailListControl.valueChanges.pipe(
        startWith(settings.claim_notifications_email?.split(',')),
        takeUntil(this.destroy$),
      ),
      this.emailListDeliveryControl.valueChanges.pipe(
        startWith(settings.delivery_claim_notifications_email?.split(',')),
        takeUntil(this.destroy$),
      ),
    ])
      .pipe(skip(1))
      .subscribe(([a, b]) => {
        this.notificationsActive = !!a?.length || !!b?.length;
      });
  }

  saveSettings() {
    this.savePending = true;

    const request: EmailNotificationsSettingsUpdate = {
      claim_email_addresses: this.emailList.length
        ? this.emailList.join(',')
        : undefined,
      delivery_claim_email_addresses: this.emailListDelivery.length
        ? this.emailListDelivery.join(',')
        : undefined,
      language_code: this.languageControl.value,
      send_notifications: this.notificationsActive,
    };

    this.notificationService
      .updateEmailNotificationsSettings(api_version, request)
      .pipe(finalize(() => (this.savePending = false)))
      .subscribe({
        next: () => {
          this.snackbar.queue(
            this.translateService.instant(
              'claim-management.dashboard.notifications_settings_saved',
            ),
            { type: DsSnackbarType.Success },
          );
          this.dialogRef.close();
        },
        error: (error) => this.handleError(error),
      });
  }

  get saveDisabled(): boolean {
    return (
      this.notificationsActive &&
      !(this.emailList.length || this.emailListDelivery.length)
    );
  }

  private handleError(error: any) {
    this.snackbar.queue(
      error.error?.message ||
        this.translateService.instant('general.error_code.error'),
      { type: DsSnackbarType.Error },
    );
  }

  get emailList(): string[] {
    return this.emailListControl.value;
  }
  get emailListDelivery(): string[] {
    return this.emailListDeliveryControl.value;
  }
}
