import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import {
  DashboardService,
  FinancialStatisticsItem,
} from '@claim-management-lib/data-access';
import { api_version } from '@claim-management-lib/feat-claim-shared';
import { DateUtils } from '@paldesk/shared-lib/utils/date-utils';
import { Observable, Subject, switchMap } from 'rxjs';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'cm-accepted-declined',
  templateUrl: './accepted-declined.component.html',
  styleUrls: ['./accepted-declined.component.scss'],
  providers: [CurrencyPipe, DatePipe],
  standalone: false,
})
export class AcceptedDeclinedComponent implements OnInit, OnDestroy {
  chartData$: Observable<any>;
  gaCurrency?: string;
  spCurrency?: string;
  showSelector = false;
  partnerType?: 'ga' | 'sp';
  showDetailedViewControl = new FormControl<boolean>(false, {
    nonNullable: true,
  });
  processingFeeDeclineGoal: number;
  gaProcessingFeeDeclineRatio: number;
  spProcessingFeeDeclineRatio: number;

  private readonly destroy$ = new Subject<void>();

  colorSchemeTotal: any = {
    domain: ['#41b563', '#ff5864'],
  };
  colorSchemeServiceFee: any = {
    domain: ['#41b563', '#ff5864'],
  };

  constructor(
    private dashboardService: DashboardService,
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
    private router: Router,
    private dateAdapter: DateAdapter<any>,
  ) {}

  mapToData(
    type: 'total' | 'service_fee',
    statisticsItems: FinancialStatisticsItem[] | undefined,
    detailedView: boolean,
  ) {
    if (!statisticsItems) {
      return [];
    }

    if (detailedView && type === 'service_fee') {
      return this.mapToDataDetailed(type, statisticsItems);
    } else {
      return this.mapToDataMerged(type, statisticsItems);
    }
  }

  mapToDataDetailed(
    type: 'total' | 'service_fee',
    statisticsItems: FinancialStatisticsItem[],
  ) {
    return statisticsItems.map((x) => {
      const accepted =
        (type === 'total'
          ? x.accepted_total_sum
          : x.accepted_processing_fee_sum) || 0;
      const requested =
        (type === 'total'
          ? x.requested_total_sum
          : x.requested_processing_fee_sum) || 0;
      return {
        name: this.datePipe.transform(x.date, 'MM/yy'),
        series: [
          {
            extra: x,
            name: 'accepted',
            value: accepted,
            total: requested,
          },
          {
            extra: x,
            name: 'declined_unknown_reason_sum',
            value: x.declined_processing_fee_unknown_reason_sum,
            total: requested,
          },
          {
            extra: x,
            name: 'declined_missing_production_data_sum',
            value: x.declined_processing_fee_missing_production_data_sum,
            total: requested,
          },
          {
            extra: x,
            name: 'declined_incomplete_basic_data_sum',
            value: x.declined_processing_fee_incomplete_basic_data_sum,
            total: requested,
          },
          {
            extra: x,
            name: 'declined_missing_data_file_sum',
            value: x.declined_processing_fee_missing_data_file_sum,
            total: requested,
          },
          {
            extra: x,
            name: 'declined_submission_after_deadline_sum',
            value: x.declined_submission_after_deadline_sum,
            total: requested,
          },
          {
            extra: x,
            name: 'declined_attachment_missing_sum',
            value: x.declined_attachment_missing_sum,
            total: requested,
          },
          {
            extra: x,
            name: 'declined_material_issue_sum',
            value: x.declined_material_issue_sum,
            total: requested,
          },
          {
            extra: x,
            name: 'declined_other_sum',
            value: x.declined_other_sum,
            total: requested,
          },
          {
            extra: x,
            name: 'declined_text_and_coding_not_identical_sum',
            value: x.declined_text_and_coding_not_identical_sum,
            total: requested,
          },
        ],
      };
    });
  }

  mapToDataMerged(
    type: 'total' | 'service_fee',
    statisticsItems: FinancialStatisticsItem[],
  ) {
    return statisticsItems.map((x) => {
      const accepted =
        (type === 'total'
          ? x.accepted_total_sum
          : x.accepted_processing_fee_sum) || 0;
      const requested =
        (type === 'total'
          ? x.requested_total_sum
          : x.requested_processing_fee_sum) || 0;
      return {
        name: this.datePipe.transform(x.date, 'MM/yy'),
        series: [
          {
            name: 'accepted',
            value: accepted,
            total: requested,
            extra: x,
          },
          {
            name: 'not_accepted',
            value: requested - accepted > 0 ? requested - accepted : 0,
            total: requested,
            extra: x,
          },
        ],
      };
    });
  }

  ngOnInit(): void {
    this.chartData$ = this.dashboardService
      .getFinancialStatistics(api_version)
      .pipe(
        tap((res) => {
          this.processingFeeDeclineGoal =
            100 - res.processing_fee_acceptance_ratio_goal;
          this.gaProcessingFeeDeclineRatio =
            100 - res.general_agent_processing_fee_acceptance_ratio;
          this.spProcessingFeeDeclineRatio =
            100 - res.service_partner_processing_fee_acceptance_ratio;
          this.gaCurrency = res.general_agent_currency;
          this.spCurrency = res.service_partner_currency;
          this.showSelector = Boolean(
            res.general_agent_data?.length && res.service_partner_data?.length,
          );
          if (
            res.general_agent_data?.some(
              (x) =>
                (x.requested_total_sum && x.requested_total_sum > 0) ||
                (x.accepted_total_sum && x.accepted_total_sum > 0),
            )
          ) {
            this.partnerType = 'ga';
          } else if (
            res.service_partner_data?.some(
              (x) =>
                (x.requested_total_sum && x.requested_total_sum > 0) ||
                (x.accepted_total_sum && x.accepted_total_sum > 0),
            )
          ) {
            this.partnerType = 'sp';
          }
        }),
        switchMap((statisticsData) =>
          this.showDetailedViewControl.valueChanges.pipe(
            startWith(false),
            tap((showDetailed) =>
              this.toggleServiceFeeColorSchema(showDetailed),
            ),
            map((showDetailed) => {
              const sp_data = statisticsData.service_partner_data
                ?.slice()
                .reverse();
              const ga_data = statisticsData.general_agent_data
                ?.slice()
                .reverse();
              return {
                total_amount_data: {
                  sp: this.mapToData('total', sp_data, showDetailed),
                  ga: this.mapToData('total', ga_data, showDetailed),
                },
                service_fee_data: {
                  sp: this.mapToData('service_fee', sp_data, showDetailed),
                  ga: this.mapToData('service_fee', ga_data, showDetailed),
                },
              };
            }),
          ),
        ),
        takeUntil(this.destroy$),
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  toggleServiceFeeColorSchema(detailedView: boolean): void {
    if (detailedView) {
      this.colorSchemeServiceFee = {
        domain: [
          '#41b563',
          '#3c88ec',
          '#f3b980',
          '#4b575f',
          '#d9585e',
          '#758087',
        ],
      };
    } else {
      this.colorSchemeServiceFee = {
        domain: ['#41b563', '#ff5864'],
      };
    }
  }

  yAxisTickFormatting = (val) => {
    let suffix = '';
    if (val >= 1e9) {
      val = (val / 1e9).toFixed(1);
      suffix = 'B';
    }
    if (val >= 1e6) {
      val = (val / 1e6).toFixed(1);
      suffix = 'M';
    }
    if (val >= 1e3) {
      val = (val / 1e3).toFixed(1);
      suffix = 'K';
    }

    return val > 0
      ? this.currencyPipe.transform(
          val,
          this.partnerType === 'ga' ? this.gaCurrency : this.spCurrency,
          'symbol',
          '1.0-1',
          'en-EN',
        ) + suffix
      : 0;
  };

  goToClaimsByAcceptanceRatio(event, total: boolean) {
    if (!event.extra) {
      return;
    }
    this.router.navigate(['/claimdesk', 'custom'], {
      queryParams: {
        customFilter: 'ByAcceptanceRatio',
        acceptanceRatioFilterType: this.getAcceptanceRatioFilterTypeFromLabel(
          event.label,
        ),
        acceptanceRatioTableType: total ? 'Total' : 'HandlingLumpSum',
        acceptanceRatioPartnerType: !this.showSelector
          ? undefined
          : this.partnerType === 'sp'
            ? 'ServicePartner'
            : 'GeneralAgent',
        start_date: `date(${event.extra.date})`,
        end_date: `date(${DateUtils.toISODateStringLocal(
          this.dateAdapter.addCalendarMonths(new Date(event.extra.date), 1),
        )})`,
      },
    });
  }

  getAcceptanceRatioFilterTypeFromLabel(
    label: string,
  ):
    | 'Accepted'
    | 'NotAccepted'
    | 'DeclinedUnknownReason'
    | 'DeclinedMissingProductionData'
    | 'DeclinedIncompleteBasicData'
    | 'DeclinedMissingDataFile'
    | 'DeclinedSubmissionAfterDeadline'
    | 'DeclinedMaterialIssue'
    | 'DeclinedAttachmentMissing'
    | 'DeclinedTextAndCodingNotIdentical'
    | 'DeclinedOther'
    | undefined {
    switch (label) {
      case 'accepted':
        return 'Accepted';

      case 'not_accepted':
        return 'NotAccepted';

      case 'declined_unknown_reason_sum':
        return 'DeclinedUnknownReason';

      case 'declined_missing_production_data_sum':
        return 'DeclinedMissingProductionData';

      case 'declined_incomplete_basic_data_sum':
        return 'DeclinedIncompleteBasicData';

      case 'declined_missing_data_file_sum':
        return 'DeclinedMissingDataFile';

      case 'declined_submission_after_deadline_sum':
        return 'DeclinedSubmissionAfterDeadline';

      case 'declined_attachment_missing_sum':
        return 'DeclinedAttachmentMissing';

      case 'declined_material_issue_sum':
        return 'DeclinedMaterialIssue';

      case 'declined_other_sum':
        return 'DeclinedOther';

      case 'declined_text_and_coding_not_identical_sum':
        return 'DeclinedTextAndCodingNotIdentical';

      default:
        return undefined;
    }
  }
}
