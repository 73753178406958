/**
 * v1.1 - Claim Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1_claim_management
 * Contact: F.John@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Attachment { 
    
    attachment_id?: number;
    
    file_name: string;
    
    attachment_type: AttachmentAttachmentType;
    
    file_uri?: string;
    
    thumbnail_uri?: string;
    
    deleted: boolean;
    
    rotation_angle?: number;
    
    is_visible_to_sp?: boolean;
    
    belong_to_sp?: boolean;
    
    required_data_code?: string;
}
export enum AttachmentAttachmentType {
    Attachment = 'Attachment',
    Image = 'Image',
    Video = 'Video'
};



