import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import {
  ClaimStateFilterRow,
  GetUserContextResponse,
} from '@claim-management-lib/data-access';
import {
  AccessService,
  LinkService,
  RecentClaimService,
} from '@claim-management-lib/feat-claim-shared';
import { NotificationSettingsComponent } from '@claim-management-lib/feat-dashboard';
import { APPS, PARTNERTYPES } from '@config';
import { Menu, MenuItem } from '@design-system/feature/app-wrapper-v2';
import { UserService } from '@features/auth';
import { filterTruthy } from '@shared-lib/rxjs';
import {
  Subject,
  catchError,
  filter,
  first,
  forkJoin,
  map,
  of,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';

@Component({
  selector: 'cm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent implements OnInit, OnDestroy {
  helpIconUrl: string;
  menu: Menu = {
    title: 'Claim Management',
    titleIcon: 'business_center',
    navigation: [],
  };
  loadingNav = true;
  claimDeskItems: { [rowType: string]: MenuItem[] } = {};
  destroy$ = new Subject<void>();

  constructor(
    private accessService: AccessService,
    public userService: UserService,
    private dialog: MatDialog,
    private recentClaimService: RecentClaimService,
    private linkService: LinkService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.userService.isAuthorized$
      .pipe(
        filterTruthy(),
        take(1),
        switchMap(() => {
          if (
            this.userService.isOneOfPartnerTypes([
              PARTNERTYPES.GENERAL_AGENT,
              PARTNERTYPES.SERVICE_PARTNER,
            ])
          ) {
            this.recentClaimService.getStateFilter();
            return forkJoin([
              this.accessService.getAccessStatus(),
              this.recentClaimService.stateFilterObservable.pipe(first()),
            ]);
          } else {
            return forkJoin([this.accessService.getAccessStatus()]);
          }
        }),
        catchError(() => {
          this.loadingNav = false;
          return of([]);
        }),
      )
      .subscribe((res) => {
        if (res.length) {
          this.assembleSidebar(res[0], res[1]);
        }
        this.loadingNav = false;
        // badge count refresh
        this.recentClaimService.stateFilterObservable
          .pipe(takeUntil(this.destroy$))
          .subscribe((stateFilter) => {
            this.setBadgeCounts(stateFilter);
          });
      });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event) => event as NavigationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe((event) => {
        if (event.url.startsWith('/link/')) {
          this.linkService.resolveLink(
            event.url.substring(event.url.lastIndexOf('/') + 1),
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  assembleSidebar(
    claimUserContext: GetUserContextResponse,
    claimdeskStates?: ClaimStateFilterRow[],
  ) {
    const showDashboard =
      this.userService.isOneOfPartnerTypes([
        PARTNERTYPES.GENERAL_AGENT,
        PARTNERTYPES.SERVICE_PARTNER,
      ]) &&
      this.userService.hasOneRole([
        APPS.ECLAIM.ROLES.USER,
        APPS.ECLAIM.ROLES.DEALER,
      ]);

    if (showDashboard) {
      this.menu.navigation.push({
        routerLink: '/dashboard',
        title: 'Dashboard',
        icon: 'dashboard',
      });
    }

    if (claimdeskStates) {
      const claimdesk: MenuItem = {
        routerLink: '/claimdesk',
        title: 'Claimdesk',
        icon: 'assignment',
        children: [
          {
            routerLink: '/claimdesk/all',
            title: 'claim-management.dashboard.all',
          },
        ],
      };

      claimdeskStates.forEach((filterGroup) => {
        const group: MenuItem = {
          title: 'claim-management.dashboard.' + filterGroup.row_type,
          routerLink: '/claimdesk',
          children: [],
        };

        this.claimDeskItems[filterGroup.row_type] = [];

        filterGroup.states.forEach((state) => {
          if (group.children) {
            const claimDeskItem: MenuItem = {
              title: 'claim-management.dashboard.' + state.state_name,
              routerLink: '/claimdesk/' + state.state_name,
              queryParamsHandling: 'merge',
              badge: state.count,
            };
            group.children.push(claimDeskItem);
            this.claimDeskItems[filterGroup.row_type].push(claimDeskItem);
          }
        });
        if (claimdesk.children) {
          claimdesk.children.push(group);
        }
      });

      this.menu.navigation.push(claimdesk);
    }

    if (claimUserContext.ram_administration_access) {
      this.menu.navigation.push({
        routerLink: '/ram',
        title: 'claim-management.ram.recall_action_management',
        icon: 'settings',
      });
    }

    if (claimUserContext.claim_on_delivery_access) {
      this.menu.navigation.push({
        routerLink: '/delivery',
        title: 'claim-management.claim_on_delivery.claim_on_delivery',
        icon: 'sync_alt',
      });
    }

    if (claimUserContext.is_decision_general_agent) {
      this.menu.navigation.push({
        routerLink: '/maintenance',
        title: 'claim-management.maintenance.maintenance',
        icon: 'settings',
        children: [
          {
            routerLink: '/maintenance/price-list',
            title: 'claim-management.maintenance.price_list',
          },
          {
            routerLink: '/maintenance/categories',
            title: 'claim-management.maintenance.categories',
          },
        ],
      });
    }

    if (showDashboard) {
      this.menu.navigation.push({
        title: 'claim-management.dashboard.notifications',
        icon: 'mail',
        onClick: () => {
          this.dialog.open(NotificationSettingsComponent, {
            width: '400px',
            disableClose: true,
          });
        },
      });
    }
  }

  setBadgeCounts(stateFilter: ClaimStateFilterRow[]): void {
    stateFilter.forEach((filterGroup) => {
      const menuGroup = this.claimDeskItems[filterGroup.row_type];
      filterGroup.states.forEach((state) => {
        const menuItem = menuGroup.find(
          (x) => x.title === `claim-management.dashboard.${state.state_name}`,
        );
        if (menuItem) {
          menuItem.badge = state.count;
        }
      });
    });
  }
}
