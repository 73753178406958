import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
  standalone: false,
})
export class HighlightPipe implements PipeTransform {
  transform(text: string | undefined, search): string {
    if (!text) return '';

    if (!search) return text;

    //eslint-disable-next-line no-useless-escape
    let pattern = search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
    pattern = pattern
      .split(' ')
      .filter((t) => t.length > 0)
      .join('|');
    const regex = new RegExp(pattern, 'gi');

    return search
      ? text.replace(
          regex,
          (match) => `<span class="highlight">${match}</span>`,
        )
      : text;
  }
}
