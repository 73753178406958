import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FaultCausingPartValidationData } from './fault-causing-part.models';

@Component({
  selector: 'cm-fault-causing-part',
  templateUrl: './fault-causing-part.component.html',
  styleUrls: ['./fault-causing-part.component.scss'],
  standalone: false,
})
export class FaultCausingPartComponent {
  @Input() materialNumberRequired: boolean;
  @Input() servicePartnerId: number;
  @Input() set sparePartValidationDto(val: FaultCausingPartValidationData) {
    this._sparePartValidationDto = val;
  }
  get sparePartValidationDto(): FaultCausingPartValidationData {
    return this._sparePartValidationDto;
  }
  @Input() form: FormGroup;
  @Input() requiredData: string;

  private _sparePartValidationDto: FaultCausingPartValidationData;

  preventDefault(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }
}
