import { Pipe, PipeTransform } from '@angular/core';
import { ClaimDecisionClaimState } from '@claim-management-lib/data-access';

@Pipe({
  name: 'translateAccepted',
  standalone: false,
})
export class TranslateAcceptedPipe implements PipeTransform {
  claimStateEnum = ClaimDecisionClaimState;
  transform(claimState: string | undefined): string {
    if (
      claimState === this.claimStateEnum.DeclinedByGeneralAgent ||
      claimState === this.claimStateEnum.AcceptedFullyByGeneralAgent ||
      claimState === this.claimStateEnum.AcceptedPartlyByGeneralAgent ||
      !claimState
    ) {
      return 'claim-management.decision.accepted';
    } else {
      return 'claim-management.decision.to_be_accepted';
    }
  }
}
