import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContractTermsComponent } from '../contract-terms/contract-terms.component';
import { SummaryInfoCardData } from './summary.models';

@Component({
  selector: 'cm-summary-info-card',
  templateUrl: './summary-info-card.component.html',
  styleUrls: ['./summary-info-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SummaryInfoCardComponent {
  @Input() data: SummaryInfoCardData[];
  constructor(private dialog: MatDialog) {}

  openContractTerms() {
    this.dialog.open(ContractTermsComponent);
  }
}
